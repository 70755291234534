import $ from "jquery";
var config = window.ecc;

var appService = function () {
  this.login = function () {
    var endpoint = `${config.domain}/auth/v1.0/signin/uid`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "POST",
        dataType: "json",
        data: JSON.stringify({
          login: config.app_login,
          password: config.app_password
        }),
        headers: {
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          window.app_token = data.access_token;
          var response = { "data": data, "status": status, "xhr": xhr, "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Autenticação`);
          resolve(response);
        }
      );
    });
  };

  this.getProjectDetails = function () {
    var endpoint = `${config.domain}/auth/v1.0/project/uid/f66db1c0-6ddd-ec11-b656-dc98407f08bf`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.app_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Detalhes do projeto`);
          resolve(response);
        }
      );
    });
  };

  this.getCourses = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/courses?register_status=NotAttempted&register_status=Started`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.app_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Listagem de cursos`);
          resolve(response);
        }
      );
    });
  };

  this.getCourse = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/course/uid/0cd0cf5f-6edd-ec11-b656-dc98407f08bf`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.app_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Detalhes do curso`);
          resolve(response);
        }
      );
    });
  };

  this.getUserCourseStatus = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/register/uid/dd8bbaba-6edd-ec11-b656-dc98407f08bf/status`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.app_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Status de progresso no curso`);
          resolve(response);
        }
      );
    });
  };

  this.getCourseTree = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/register/uid/dd8bbaba-6edd-ec11-b656-dc98407f08bf/nodes/tree`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.app_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Trilha do curso`);
          resolve(response);
        }
      );
    });
  };

  this.getCourseInteractionDetails = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/interaction/uid/fddad4ca-6edd-ec11-b656-dc98407f08bf/details`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.app_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Detalhes da interação do curso`);
          resolve(response);
        }
      );
    });
  };

};


var AppService = new appService();

export { AppService };
