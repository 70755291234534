import React, { Component } from 'react';
import Logo from './logo.png';
import './header.css';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.openHome = this.openHome.bind(this);
  }
  
  openHome() {
    window.location = window.location.origin + "/#/";
  }

  render() {
    return (
      <div className="rdp-react-template-component-header">
        <img className="rdp-react-template-component-header-logo" src={Logo} onClick={() => this.openHome()}/>
      </div>
    );
  }
}
