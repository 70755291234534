import $ from "jquery";
var config = window.ecc;

var damService = function () {
  this.login = function () {
    var endpoint = `${config.domain}/auth/v1.0/signin/uid`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "POST",
        dataType: "json",
        data: JSON.stringify({
          login: config.dam_login,
          password: config.dam_password
        }),
        headers: {
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          window.dam_token = data.access_token;
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Autenticação`);
          resolve(response);
        }
      );
    });
  };

  this.getFilesList = function () {
    var endpoint = `${config.domain}/storage/v1.0/search?filter=(content_type%20eq%20%27digitalpages/template%27)%20and%20(project_uid%20eq%20%27f66db1c0-6ddd-ec11-b656-dc98407f08bf%27)&orderBy=last_modified%20desc`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.dam_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Listagem de arquivos`);
          resolve(response);
        }
      );
    });
  };

  this.getComposerFilesList = function () {
    var endpoint = `${config.domain}/storage/v1.0/search?filter=(project_uid%20eq%20%27f66db1c0-6ddd-ec11-b656-dc98407f08bf%27)%20and%20(content_type%20eq%20%27digitalpages/composer%27%20)&orderBy=last_modified%20desc`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.dam_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Filtrar por composições`);
          resolve(response);
        }
      );
    });
  };

  this.getImagesFilesList = function () {
    var endpoint = `${config.domain}/storage/v1.0/search?filter=(project_uid%20eq%20%27f66db1c0-6ddd-ec11-b656-dc98407f08bf%27)%20and%20(content_type%20eq%20%27image/jpg%27%20or%20content_type%20eq%20%27image/jpeg%27%20or%20content_type%20eq%20%27image/png%27%20or%20content_type%20eq%20%27image/gif%27%20or%20content_type%20eq%20%27image/svg+xml%27%20)&orderBy=last_modified%20desc`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.dam_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Filtrar por imagens`);
          resolve(response);
        }
      );
    });
  };

  this.getVideosFilesList = function () {
    var endpoint = `${config.domain}/storage/v1.0/search?filter=(project_uid%20eq%20%27f66db1c0-6ddd-ec11-b656-dc98407f08bf%27)%20and%20(content_type%20eq%20%27digitalpages/video%27%20)&orderBy=last_modified%20desc`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.dam_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Filtrar por vídeos`);
          resolve(response);
        }
      );
    });
  };

};


var DamService = new damService();

export { DamService };
