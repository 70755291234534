import React, { Component } from 'react';
import {Route} from 'react-router-dom';
import './reset.css';
import './App.css';

import {
  ViewHome
} from './views';

class App extends Component {
  render() {
    return (
      <>
        <Route exact={true} path="/" component={ViewHome}/>
        <Route exact={true} path="/:client" component={ViewHome}/>
      </>
    );
  }
}

export default App;
