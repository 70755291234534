import React, {Component} from 'react';
import {AppService} from '../../services/app';
import {ManagerService} from '../../services/manager';
import {DamService} from '../../services/dam';
import {ComposerService} from '../../services/composer';
import img_app_course_interaction_details from './screenshots/app_course_interaction_details.png';
import img_app_course_tree from './screenshots/app_course_tree.png';
import img_app_course from './screenshots/app_course.png';
import img_app_courses from './screenshots/app_courses.png';
import img_app_login from './screenshots/app_login.png';
import img_app_project_details from './screenshots/app_project_details.png';
import img_app_user_course_status from './screenshots/app_user_course_status.png';
import img_composer_compositions_list from './screenshots/composer_compositions_list.png';
import img_composer_compositions_search_suggestions from './screenshots/composer_compositions_search_suggestions.png';
import img_composer_compositions_search from './screenshots/composer_compositions_search.png';
import img_composer_login from './screenshots/composer_login.png';
import img_dam_composer_files_list from './screenshots/dam_composer_files_list.png';
import img_dam_files_list from './screenshots/dam_files_list.png';
import img_dam_images_files_list from './screenshots/dam_images_files_list.png';
import img_dam_login from './screenshots/dam_login.png';
import img_dam_video_files_list from './screenshots/dam_video_files_list.png';
import img_manager_admin_info from './screenshots/manager_admin_info.png';
import img_manager_certificates from './screenshots/manager_certificates.png';
import img_manager_course_info from './screenshots/manager_course_info.png';
import img_manager_course_registers from './screenshots/manager_course_registers.png';
import img_manager_course_tree from './screenshots/manager_course_tree.png';
import img_manager_courses_list from './screenshots/manager_courses_list.png';
import img_manager_insights_dashboard from './screenshots/manager_insights_dashboard.png';
import img_manager_login from './screenshots/manager_login.png';
import img_manager_project_info from './screenshots/manager_project_info.png';
import img_manager_projects_list from './screenshots/manager_projects_list.png';
import img_manager_user_info from './screenshots/manager_user_info.png';
import img_manager_user_registers from './screenshots/manager_user_registers.png';
import img_manager_users_list from './screenshots/manager_users_list.png';
import './home.css';

import {
  Header,
} from '../../components';

export default class ViewHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      "current_endpoint": null,
      "app_login": null,
      "app_project_details": null,
      "app_courses": null,
      "app_user_course_status": null,
      "app_course_tree": null,
      "app_course_interaction_details": null,
      "manager_login": null,
      "manager_admin_info": null,
      "manager_projects_list": null,
      "manager_project_info": null,
      "manager_insights_dashboard": null,
      "manager_users_list": null,
      "manager_user_info": null,
      "manager_user_registers": null,
      "manager_courses_list": null,
      "manager_course_info": null,
      "manager_course_tree": null,
      "manager_course_registers": null,
      "manager_certificates": null,
      "dam_login": null,
      "dam_files_list": null,
      "dam_composer_files_list": null,
      "dam_images_files_list": null,
      "dam_video_files_list": null,
      "composer_login": null,
      "composer_compositions_list": null,
      "composer_compositions_search": null,
      "composer_compositions_search_suggestions": null
    }
  }

  componentDidMount() {
    var _this = this;

    _this.DispatchRequests();
    setInterval(function() {
      _this.DispatchRequests();
    }, 300000)
  }

  DispatchRequests() {
    this.AppLogin();
    this.ManagerLogin();
    this.DamLogin();
    this.ComposerLogin();
  }

  async AppLogin() {
    var login = await AppService.login();
    this.setState({
      "app_login": login
    });

    console.log(login)

    this.AppGetProjectDetails();
    this.AppGetCourses();
    this.AppGetCourse();
    this.AppGetUserCourseStatus();
    this.AppGetCourseTree();
    this.AppGetCourseInteractionDetails();
  }

  async AppGetProjectDetails() {
    var project = await AppService.getProjectDetails();
    this.setState({
      "app_project_details": project
    })
  }

  async AppGetCourses() {
    var courses = await AppService.getCourses();
    this.setState({
      "app_courses": courses
    })
  }

  async AppGetCourse() {
    var course = await AppService.getCourse();
    this.setState({
      "app_course": course
    })
  }

  async AppGetUserCourseStatus() {
    var course = await AppService.getUserCourseStatus();
    this.setState({
      "app_user_course_status": course
    })
  }

  async AppGetCourseTree() {
    var course = await AppService.getCourseTree();
    this.setState({
      "app_course_tree": course
    })
  }

  async AppGetCourseInteractionDetails() {
    var course = await AppService.getCourseInteractionDetails();
    this.setState({
      "app_course_interaction_details": course
    })
  }

  async ManagerLogin() {
    var login = await ManagerService.login();
    this.setState({
      "manager_login": login
    });

    this.ManagerGetAdminInfo();
    this.ManagerGetProjectsList();
    this.ManagerGetProjectInfo();
    this.ManagerGetInsightsDashboard();
    this.ManagerGetUsersList();
    this.ManagerGetUserInfo();
    this.ManagerGetUserRegisters();
    this.ManagerGetCoursesList();
    this.ManagerGetCourseInfo();
    this.ManagerGetCourseTree();
    this.ManagerGetCourseRegisters();
    this.ManagerGetCertificatesList();
  }

  async ManagerGetAdminInfo() {
    var user = await ManagerService.getAdminInfo();
    this.setState({
      "manager_admin_info": user
    });
  }

  async ManagerGetProjectsList() {
    var projects = await ManagerService.getProjectsList();
    this.setState({
      "manager_projects_list": projects
    });
  }

  async ManagerGetProjectInfo() {
    var project = await ManagerService.getProjectsInfo();
    this.setState({
      "manager_project_info": project
    });
  }

  async ManagerGetInsightsDashboard() {
    var dashboard = await ManagerService.getInsightsDashboards();
    this.setState({
      "manager_insights_dashboard": dashboard
    });
  }

  async ManagerGetUsersList() {
    var users = await ManagerService.getUsersList();
    this.setState({
      "manager_users_list": users
    })
  }

  async ManagerGetUserInfo() {
    var user = await ManagerService.getUserInfo();
    this.setState({
      "manager_user_info": user
    });
  }

  async ManagerGetUserRegisters() {
    var registers = await ManagerService.getUserRegisters();
    this.setState({
      "manager_user_registers": registers
    });
  }

  async ManagerGetCoursesList() {
    var courses = await ManagerService.getCoursesList();
    this.setState({
      "manager_courses_list": courses
    });
  }

  async ManagerGetCourseInfo() {
    var courses = await ManagerService.getCoursesList();
    this.setState({
      "manager_course_info": courses
    });
  }

  async ManagerGetCourseTree() {
    var courses = await ManagerService.getCoursesList();
    this.setState({
      "manager_course_tree": courses
    });
  }

  async ManagerGetCourseRegisters() {
    var courses = await ManagerService.getCoursesList();
    this.setState({
      "manager_course_registers": courses
    });
  }

  async ManagerGetCertificatesList() {
    var certificates = await ManagerService.getCertificatesList();
    this.setState({
      "manager_certificates": certificates
    });
  }

  async DamLogin() {
    var login = await DamService.login();
    this.setState({
      "dam_login": login
    });

    this.DamGetFilesList();
    this.DamGetComposerFilesList();
    this.DamGetImagesFilesList();
    this.DamGetVideosFilesList();
  }

  async DamGetFilesList() {
    var files = await DamService.getFilesList();
    this.setState({
      "dam_files_list": files
    });
  }

  async DamGetComposerFilesList() {
    var files = await DamService.getComposerFilesList();
    this.setState({
      "dam_composer_files_list": files
    });
  }

  async DamGetImagesFilesList() {
    var files = await DamService.getImagesFilesList();
    this.setState({
      "dam_images_files_list": files
    });
  }

  async DamGetVideosFilesList() {
    var files = await DamService.getVideosFilesList();
    this.setState({
      "dam_video_files_list": files
    });
  }

  async ComposerLogin() {
    var login = await ComposerService.login();
    this.setState({
      "composer_login": login
    });

    this.ComposerGetCompositionsList();
    this.ComposerGetCompositionsSearch();
    this.ComposerGetCompositionsSearchSuggestions();
  }

  async ComposerGetCompositionsList() {
    var compositions = await ComposerService.getCompositionsList();
    this.setState({
      "composer_compositions_list": compositions
    });
  }

  async ComposerGetCompositionsSearch() {
    var compositions = await ComposerService.getCompositionsSearch();
    this.setState({
      "composer_compositions_search": compositions
    });
  }

  async ComposerGetCompositionsSearchSuggestions() {
    var suggestions = await ComposerService.getCompositionsSearchSuggestions();
    this.setState({
      "composer_compositions_search_suggestions": suggestions
    });
  }

  selectEndpoint(label, request, type) {
    this.setState({
      "current_endpoint": {
        "label": label,
        "request": request,
        "type": type
      }
    });
    console.log(label);
    console.log(request);
    console.log(type);
  }

  unselectEndpoint() {
    this.setState({
      "current_endpoint": null
    });
  }

  getImg(type) {
    if (type === "app_course_interaction_details") return img_app_course_interaction_details;
    if (type === "app_course_tree") return img_app_course_tree;
    if (type === "app_course") return img_app_course;
    if (type === "app_courses") return img_app_courses;
    if (type === "app_login") return img_app_login;
    if (type === "app_project_details") return img_app_project_details;
    if (type === "app_user_course_status") return img_app_user_course_status;
    if (type === "composer_compositions_list") return img_composer_compositions_list;
    if (type === "composer_compositions_search_suggestions") return img_composer_compositions_search_suggestions;
    if (type === "composer_compositions_search") return img_composer_compositions_search;
    if (type === "composer_login") return img_composer_login;
    if (type === "dam_composer_files_list") return img_dam_composer_files_list;
    if (type === "dam_files_list") return img_dam_files_list;
    if (type === "dam_images_files_list") return img_dam_images_files_list;
    if (type === "dam_login") return img_dam_login;
    if (type === "dam_video_files_list") return img_dam_video_files_list;
    if (type === "manager_admin_info") return img_manager_admin_info;
    if (type === "manager_certificates") return img_manager_certificates;
    if (type === "manager_course_info") return img_manager_course_info;
    if (type === "manager_course_registers") return img_manager_course_registers;
    if (type === "manager_course_tree") return img_manager_course_tree;
    if (type === "manager_courses_list") return img_manager_courses_list;
    if (type === "manager_insights_dashboard") return img_manager_insights_dashboard;
    if (type === "manager_login") return img_manager_login;
    if (type === "manager_project_info") return img_manager_project_info;
    if (type === "manager_projects_list") return img_manager_projects_list;
    if (type === "manager_user_info") return img_manager_user_info;
    if (type === "manager_user_registers") return img_manager_user_registers;
    if (type === "manager_users_list") return img_manager_users_list;
  }

  render() {
    const {
      current_endpoint,
      app_login,
      app_project_details,
      app_courses,
      app_course,
      app_user_course_status,
      app_course_tree,
      app_course_interaction_details,
      manager_login,
      manager_admin_info,
      manager_projects_list,
      manager_project_info,
      manager_insights_dashboard,
      manager_users_list,
      manager_user_info,
      manager_user_registers,
      manager_courses_list,
      manager_course_info,
      manager_course_tree,
      manager_course_registers,
      manager_certificates,
      dam_login,
      dam_files_list,
      dam_composer_files_list,
      dam_images_files_list,
      dam_video_files_list,
      composer_login,
      composer_compositions_list,
      composer_compositions_search,
      composer_compositions_search_suggestions
    } = this.state;
    return <div className="ecc-status-view-home">
      <div className="ecc-status-view-home-box">
        <div className="ecc-status-view-home-header">
          <span className="ecc-status-view-home-header-logo"></span>
        </div>
        <div className="ecc-status-view-home-content">
          <div className="ecc-status-view-home-items">
            <h3 className="ecc-status-view-home-items-title">App</h3>
            {app_login &&
              <div className={`ecc-status-view-home-item ${app_login.status}`} onClick={() => this.selectEndpoint("API de Autenticação", app_login, "app_login")}>
                <span className="ecc-status-view-home-item-label">API de Autenticação</span>
                <span className="ecc-status-view-home-item-code">{app_login.xhr.status}</span>
              </div>
            }
            {app_project_details &&
              <div className={`ecc-status-view-home-item ${app_project_details.status}`} onClick={() => this.selectEndpoint("API de Detalhes do projeto", app_project_details, "app_project_details")}>
                <span className="ecc-status-view-home-item-label">API de Detalhes do projeto</span>
                <span className="ecc-status-view-home-item-code">{app_project_details.xhr.status}</span>
              </div>
            }
            {app_courses &&
              <div className={`ecc-status-view-home-item ${app_courses.status}`} onClick={() => this.selectEndpoint("API de Listagem de cursos", app_courses, "app_courses")}>
                <span className="ecc-status-view-home-item-label">API de Listagem de cursos</span>
                <span className="ecc-status-view-home-item-code">{app_courses.xhr.status}</span>
              </div>
            }
            {app_course &&
              <div className={`ecc-status-view-home-item ${app_course.status}`} onClick={() => this.selectEndpoint("API de Detalhes do curso", app_course, "app_course")}>
                <span className="ecc-status-view-home-item-label">API de Detalhes do curso</span>
                <span className="ecc-status-view-home-item-code">{app_course.xhr.status}</span>
              </div>
            }
            {app_user_course_status &&
              <div className={`ecc-status-view-home-item ${app_user_course_status.status}`} onClick={() => this.selectEndpoint("API de Status de progresso no curso", app_user_course_status, "app_user_course_status")}>
                <span className="ecc-status-view-home-item-label">API de Status de progresso no curso</span>
                <span className="ecc-status-view-home-item-code">{app_user_course_status.xhr.status}</span>
              </div>
            }
            {app_course_tree &&
              <div className={`ecc-status-view-home-item ${app_course_tree.status}`} onClick={() => this.selectEndpoint("API de Trilha do curso", app_course_tree, "app_course_tree")}>
                <span className="ecc-status-view-home-item-label">API de Trilha do curso</span>
                <span className="ecc-status-view-home-item-code">{app_course_tree.xhr.status}</span>
              </div>
            }
            {app_course_interaction_details &&
              <div className={`ecc-status-view-home-item ${app_course_interaction_details.status}`} onClick={() => this.selectEndpoint("API de Detalhes da interação do curso", app_course_interaction_details, "app_course_interaction_details")}>
                <span className="ecc-status-view-home-item-label">API de Detalhes da interação do curso</span>
                <span className="ecc-status-view-home-item-code">{app_course_interaction_details.xhr.status}</span>
              </div>
            }
          </div>
          <div className="ecc-status-view-home-items">
            <h3 className="ecc-status-view-home-items-title">Manager</h3>
            {manager_login &&
              <div className={`ecc-status-view-home-item ${manager_login.status}`} onClick={() => this.selectEndpoint("API de Autenticação", manager_login, "manager_login")}>
                <span className="ecc-status-view-home-item-label">API de Autenticação</span>
                <span className="ecc-status-view-home-item-code">{manager_login.xhr.status}</span>
              </div>
            }
            {manager_admin_info &&
              <div className={`ecc-status-view-home-item ${manager_admin_info.status}`} onClick={() => this.selectEndpoint("API de Informações do administrador", manager_admin_info, "manager_admin_info")}>
                <span className="ecc-status-view-home-item-label">API de Informações do administrador</span>
                <span className="ecc-status-view-home-item-code">{manager_admin_info.xhr.status}</span>
              </div>
            }
            {manager_projects_list &&
              <div className={`ecc-status-view-home-item ${manager_projects_list.status}`} onClick={() => this.selectEndpoint("API de Listagem de projetos", manager_projects_list, "manager_projects_list")}>
                <span className="ecc-status-view-home-item-label">API de Listagem de projetos</span>
                <span className="ecc-status-view-home-item-code">{manager_projects_list.xhr.status}</span>
              </div>
            }
            {manager_project_info &&
              <div className={`ecc-status-view-home-item ${manager_project_info.status}`} onClick={() => this.selectEndpoint("API de Projeto ativo", manager_project_info, "manager_project_info")}>
                <span className="ecc-status-view-home-item-label">API de Projeto ativo</span>
                <span className="ecc-status-view-home-item-code">{manager_project_info.xhr.status}</span>
              </div>
            }
            {manager_insights_dashboard &&
              <div className={`ecc-status-view-home-item ${manager_insights_dashboard.status}`} onClick={() => this.selectEndpoint("API de Dashboards analíticos", manager_insights_dashboard, "manager_insights_dashboard")}>
                <span className="ecc-status-view-home-item-label">API de Dashboards analíticos</span>
                <span className="ecc-status-view-home-item-code">{manager_insights_dashboard.xhr.status}</span>
              </div>
            }
            {manager_users_list &&
              <div className={`ecc-status-view-home-item ${manager_users_list.status}`} onClick={() => this.selectEndpoint("API de Listagem de usuários", manager_users_list, "manager_users_list")}>
                <span className="ecc-status-view-home-item-label">API de Listagem de usuários</span>
                <span className="ecc-status-view-home-item-code">{manager_users_list.xhr.status}</span>
              </div>
            }
            {manager_user_info &&
              <div className={`ecc-status-view-home-item ${manager_user_info.status}`} onClick={() => this.selectEndpoint("API de Informações de um usuário", manager_user_info, "manager_user_info")}>
                <span className="ecc-status-view-home-item-label">API de Informações de um usuário</span>
                <span className="ecc-status-view-home-item-code">{manager_user_info.xhr.status}</span>
              </div>
            }
            {manager_user_registers &&
              <div className={`ecc-status-view-home-item ${manager_user_registers.status}`} onClick={() => this.selectEndpoint("API de Matrículas de um usuário", manager_user_registers, "manager_user_registers")}>
                <span className="ecc-status-view-home-item-label">API de Matrículas de um usuário</span>
                <span className="ecc-status-view-home-item-code">{manager_user_registers.xhr.status}</span>
              </div>
            }
            {manager_courses_list &&
              <div className={`ecc-status-view-home-item ${manager_courses_list.status}`} onClick={() => this.selectEndpoint("API de Listagem de cursos", manager_courses_list, "manager_courses_list")}>
                <span className="ecc-status-view-home-item-label">API de Listagem de cursos</span>
                <span className="ecc-status-view-home-item-code">{manager_courses_list.xhr.status}</span>
              </div>
            }
            {manager_course_info &&
              <div className={`ecc-status-view-home-item ${manager_course_info.status}`} onClick={() => this.selectEndpoint("API de Informações de um curso", manager_course_info, "manager_course_info")}>
                <span className="ecc-status-view-home-item-label">API de Informações de um curso</span>
                <span className="ecc-status-view-home-item-code">{manager_course_info.xhr.status}</span>
              </div>
            }
            {manager_course_tree &&
              <div className={`ecc-status-view-home-item ${manager_course_tree.status}`} onClick={() => this.selectEndpoint("API de Trilha de um curso", manager_course_tree, "manager_course_tree")}>
                <span className="ecc-status-view-home-item-label">API de Trilha de um curso</span>
                <span className="ecc-status-view-home-item-code">{manager_course_tree.xhr.status}</span>
              </div>
            }
            {manager_course_registers &&
              <div className={`ecc-status-view-home-item ${manager_course_registers.status}`} onClick={() => this.selectEndpoint("API de Ofertas de um curso", manager_course_registers, "manager_course_registers")}>
                <span className="ecc-status-view-home-item-label">API de Ofertas de um curso</span>
                <span className="ecc-status-view-home-item-code">{manager_course_registers.xhr.status}</span>
              </div>
            }
            {manager_certificates &&
              <div className={`ecc-status-view-home-item ${manager_certificates.status}`} onClick={() => this.selectEndpoint("API de Listagem de certificados", manager_certificates, "manager_certificates")}>
                <span className="ecc-status-view-home-item-label">API de Listagem de certificados</span>
                <span className="ecc-status-view-home-item-code">{manager_certificates.xhr.status}</span>
              </div>
            }

          </div>
          <div className="ecc-status-view-home-items">
            <h3 className="ecc-status-view-home-items-title">Dam</h3>
            {dam_login &&
              <div className={`ecc-status-view-home-item ${dam_login.status}`} onClick={() => this.selectEndpoint("API de Autenticação", dam_login, "dam_login")}>
                <span className="ecc-status-view-home-item-label">API de Autenticação</span>
                <span className="ecc-status-view-home-item-code">{dam_login.xhr.status}</span>
              </div>
            }
            {dam_files_list &&
              <div className={`ecc-status-view-home-item ${dam_files_list.status}`} onClick={() => this.selectEndpoint("API de Listagem de arquivos", dam_files_list, "dam_files_list")}>
                <span className="ecc-status-view-home-item-label">API de Listagem de arquivos</span>
                <span className="ecc-status-view-home-item-code">{dam_files_list.xhr.status}</span>
              </div>
            }
            {dam_composer_files_list &&
              <div className={`ecc-status-view-home-item ${dam_composer_files_list.status}`} onClick={() => this.selectEndpoint("API de Filtrar por composições", dam_composer_files_list, "dam_composer_files_list")}>
                <span className="ecc-status-view-home-item-label">API de Filtrar por composições</span>
                <span className="ecc-status-view-home-item-code">{dam_composer_files_list.xhr.status}</span>
              </div>
            }
            {dam_images_files_list &&
              <div className={`ecc-status-view-home-item ${dam_images_files_list.status}`} onClick={() => this.selectEndpoint("API de Filtrar por imagens", dam_images_files_list, "dam_images_files_list")}>
                <span className="ecc-status-view-home-item-label">API de Filtrar por imagens</span>
                <span className="ecc-status-view-home-item-code">{dam_images_files_list.xhr.status}</span>
              </div>
            }
            {dam_video_files_list &&
              <div className={`ecc-status-view-home-item ${dam_video_files_list.status}`} onClick={() => this.selectEndpoint("API de Filtrar por vídeos", dam_video_files_list, "dam_video_files_list")}>
                <span className="ecc-status-view-home-item-label">API de Filtrar por vídeos</span>
                <span className="ecc-status-view-home-item-code">{dam_video_files_list.xhr.status}</span>
              </div>
            }

          </div>
          <div className="ecc-status-view-home-items">
            <h3 className="ecc-status-view-home-items-title">Composer</h3>
            {composer_login &&
              <div className={`ecc-status-view-home-item ${composer_login.status}`} onClick={() => this.selectEndpoint("API de Autenticação", composer_login, "composer_login")}>
                <span className="ecc-status-view-home-item-label">API de Autenticação</span>
                <span className="ecc-status-view-home-item-code">{composer_login.xhr.status}</span>
              </div>
            }
            {composer_compositions_list &&
              <div className={`ecc-status-view-home-item ${composer_compositions_list.status}`} onClick={() => this.selectEndpoint("API de Listagem de composições", composer_compositions_list, "composer_compositions_list")}>
                <span className="ecc-status-view-home-item-label">API de Listagem de composições</span>
                <span className="ecc-status-view-home-item-code">{composer_compositions_list.xhr.status}</span>
              </div>
            }
            {composer_compositions_search &&
              <div className={`ecc-status-view-home-item ${composer_compositions_search.status}`} onClick={() => this.selectEndpoint("API de Busca de composições", composer_compositions_search, "composer_compositions_search")}>
                <span className="ecc-status-view-home-item-label">API de Busca de composições</span>
                <span className="ecc-status-view-home-item-code">{composer_compositions_search.xhr.status}</span>
              </div>
            }
            {composer_compositions_search_suggestions &&
              <div className={`ecc-status-view-home-item ${composer_compositions_search_suggestions.status}`} onClick={() => this.selectEndpoint("API de Sugestão de busca", composer_compositions_search_suggestions, "composer_compositions_search_suggestions")}>
                <span className="ecc-status-view-home-item-label">API de Sugestão de busca</span>
                <span className="ecc-status-view-home-item-code">{composer_compositions_search_suggestions.xhr.status}</span>
              </div>
            }
          </div>
        </div>
      </div>

      {current_endpoint &&
        <div className="ecc-status-view-home-box-modal">
          <div className="ecc-status-view-home-box-modal-box">
            <div className="ecc-status-view-home-box-modal-header">
              <span className="ecc-status-view-home-box-modal-header-title">{current_endpoint.label}</span>
              <span className="ecc-status-view-home-box-modal-header-close" onClick={() => this.unselectEndpoint()}></span>
              
            </div>
            <div className="ecc-status-view-home-box-modal-body">
              <span className="ecc-status-view-home-box-modal-body-label">Endpoint:</span>
              <div className="ecc-status-view-home-box-modal-body-response">{current_endpoint.request.endpoint}</div>
              <span className="ecc-status-view-home-box-modal-body-label">Resposta:</span>
              <div className="ecc-status-view-home-box-modal-body-response">{JSON.stringify(current_endpoint.request.data)}</div>
              <span className="ecc-status-view-home-box-modal-body-label">Onde a API está sendo utilizada:</span>
              <img src={this.getImg(current_endpoint.type)}/>
            </div>
          </div>
        </div>
      }
    </div>;
  }
}
