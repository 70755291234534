import $ from "jquery";
var config = window.ecc;

var managerService = function () {
  this.login = function () {
    var endpoint = `${config.domain}/auth/v1.0/signin/uid`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "POST",
        dataType: "json",
        data: JSON.stringify({
          login: config.manager_login,
          password: config.manager_password
        }),
        headers: {
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          window.manager_token = data.access_token;
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Autenticação`);
          resolve(response);
        }
      );
    });
  };

  this.getAdminInfo = function () {
    var endpoint = `${config.domain}/auth/v1.0/user/me`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Informações do administrador`);
          resolve(response);
        }
      );
    });
  };

  this.getProjectsList = function () {
    var endpoint = `${config.domain}/auth/v1.0/projects`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Listagem de projetos`);
          resolve(response);
        }
      );
    });
  };

  this.getProjectsInfo = function () {
    var endpoint = `${config.domain}/auth/v1.0/project/uid/f66db1c0-6ddd-ec11-b656-dc98407f08bf`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Projeto ativo`);
          resolve(response);
        }
      );
    });
  };

  this.getInsightsDashboards = function () {
    var endpoint = `${config.domain}/insights/v1.1/report/authorized`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Dashboards analíticos`);
          resolve(response);
        }
      );
    });
  };

  this.getUsersList = function () {
    var endpoint = `${config.domain}/auth/v1.0/project/uid/f66db1c0-6ddd-ec11-b656-dc98407f08bf/users`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Listagem de usuários`);
          resolve(response);
        }
      );
    });
  };

  this.getUserInfo = function () {
    var endpoint = `${config.domain}/auth/v1.0/user/uid/2230dac4-6edd-ec11-b656-dc98407f08bf`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Informações de um usuário`);
          resolve(response);
        }
      );
    });
  };

  this.getUserRegisters = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/user/uid/2230dac4-6edd-ec11-b656-dc98407f08bf/management/registers`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Matrículas de um usuário`);
          resolve(response);
        }
      );
    });
  };

  this.getCoursesList = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/connector/uid/a3451304-6edd-ec11-b656-dc98407f08bf/courses/management`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Listagem de cursos`);
          resolve(response);
        }
      );
    });
  };

  this.getCourseInfo = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/course/uid/0cd0cf5f-6edd-ec11-b656-dc98407f08bf/management`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Informações de um curso`);
          resolve(response);
        }
      );
    });
  };

  this.getCourseTree = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/course/uid/0cd0cf5f-6edd-ec11-b656-dc98407f08bf/management/nodes/tree`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Trilha de um curso`);
          resolve(response);
        }
      );
    });
  };

  this.getCourseRegisters = function () {
    var endpoint = `${config.domain}/cms/v1.0/learning/course/uid/0cd0cf5f-6edd-ec11-b656-dc98407f08bf/management/registers`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Ofertas de um curso`);
          resolve(response);
        }
      );
    });
  };

  this.getCertificatesList = function () {
    var endpoint = `${config.domain}/storage/v1.0/search?filter=(content_type%20eq%20%27digitalpages/template%27)%20and%20(project_uid%20eq%20%27f66db1c0-6ddd-ec11-b656-dc98407f08bf%27)&top=1000&orderBy=last_modified%20desc`;
    return new Promise(function (resolve, reject) {
      $.ajax({
        url: endpoint,
        type: "GET",
        headers: {
          "Authorization": `Bearer ${window.manager_token}`,
          "Project-Key": config.project_key,
          "Api-Env": config.api_env,
        }
      }).then(
        function (data, status, xhr) {
          var response = { "data": data, "status": status, "xhr": xhr , "endpoint": endpoint }
          resolve(response);
        },
        function (error, status, xhr) {
          var response = { "data": xhr, "status": status, "xhr": error , "endpoint": endpoint }
          window.SendNotification(`Erro ${error.status} na API de Listagem de certificados`);
          resolve(response);
        }
      );
    });
  };

};


var ManagerService = new managerService();

export { ManagerService };
